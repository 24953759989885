<template>
  <div>
    <strong><feather-icon icon="LayoutIcon" /> {{ data && data.column_from && data.column_from.column_name }}</strong>
    <div class="mt-50">
      <small>{{ data && data.ticket_move_time_in ? moment(data.ticket_move_time_in).format('LLL') : '--/--' }}</small>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'TicketColumnCol',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    moment,
  },
}
</script>
