const tabs = tabsData => {
  const {
    my_ticket_count,
    ticket_done_count,
    ticket_closed_count,
    ticket_total_count,
  } = tabsData

  return {
    key: 'tab',
    items: [
      {
        name: 'ticket~to_do',
        badgeText: `${ticket_total_count}`,
        value: 'to-do',
      },
      {
        name: 'ticket~my_tickets',
        badgeText: `${my_ticket_count}`,
        value: 'my-tickets',
        filters: {
          status: 'my_tickets',
        },
      },
      {
        name: 'ticket~done',
        badgeText: `${ticket_done_count}`,
        value: 'done',
        filters: {
          status: 'done',
        },
      },
      {
        name: 'ticket~closed',
        badgeText: `${ticket_closed_count}`,
        value: 'closed',
        filters: {
          status: 'closed',
        },
      },
    ],
  }
}

export default tabs
